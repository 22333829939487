import { Button, Container } from "@mui/material";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../containers/containers.reducer";
import CustomTable from "../../../shared/CustomTable";
import notificationError from "../../../shared/notificationError";
import notificationSuccess from "../../../shared/notificationSuccess";
import { editEmployeeJob } from "../report.api";

export default function ReportFault() {

    const dispatch = useDispatch()

    const [timeGetData, setTimeGetData] = useState<any>(undefined)

    useEffect(() => {
        dispatch(setTitle("Report"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Report",
                url: ""
            },
            {
                name: "Fault Report",
                url: "/report/fault"
            }
        ]))
    }, [])

    const handleEditInline = (data: any) => {
        dispatch<any>(editEmployeeJob({
            id: data.id,
            fault_quantity_qc: data.fault_quantity_qc,
            fault_note_qc: data.fault_note_qc
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("Fault QC successfully updated")
                        .then(() => {
                            setTimeGetData(new Date().getTime())
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Container maxWidth='xl'>
            <CustomTable
                url={"/report/fault"}
                filterDate={true}
                columns={[
                    {
                        name: "Job Number",
                        key: "jobnumber_name"
                    },
                    {
                        name: "Name",
                        key: "employee_name"
                    },
                    {
                        name: "Team",
                        key: "jobnumber_project_team_name"
                    },
                    {
                        name: "KOJ",
                        key: "koj_name"
                    },
                    {
                        name: "QTY Get",
                        key: "quantity_get"
                    },
                    {
                        name: "Get Date",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    {moment(props.data.ts_start).format('DD-MM-YYYY')}
                                </td>
                            )
                        }
                    },
                    {
                        name: "Completion Date",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    {moment(props.data.ts_stop).format('DD-MM-YYYY')}
                                </td>
                            )
                        }
                    },
                    {
                        name: "Total Minutes",
                        key: "job_calculation_total_minutes"
                    },
                    {
                        name: "Fault",
                        key: "fault_quantity"
                    },
                    {
                        name: "Fault Note",
                        key: "fault_note"
                    },
                    {
                        name: "QC Fault",
                        key: "fault_quantity_qc",
                        form: {
                            type: "number"
                        }
                    },
                    {
                        name: "QC Fault Note",
                        key: "fault_note_qc",
                        form: {
                            type: "text"
                        }
                    }
                ]}
                onEditInline={handleEditInline}
                timeGetData={timeGetData}
                export={true}
            />
        </Container>
    )
}