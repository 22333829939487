import { Container } from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../containers/containers.reducer";
import CustomTable from "../../../shared/CustomTable";

export default function ReportDailyQc() {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setTitle("Report"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Report",
                url: ""
            },
            {
                name: "Qc Daily Report",
                url: "/report/qc"
            }
        ]))
    }, [])

    return (
        <Container maxWidth='xl'>
            <CustomTable
                url={"/report/qc"}
                filterDate={true}
                columns={[
                    {
                        name: "Job Number",
                        key: "jobnumber_name"
                    },
                    {
                        name: "Name",
                        key: "employee_name"
                    },
                    {
                        name: "Team",
                        key: "jobnumber_project_team_name"
                    },
                    {
                        name: "KOJ",
                        key: "koj_name"
                    },
                    {
                        name: "QTY Get",
                        key: "quantity_get"
                    },
                    {
                        name: "Billable QTY",
                        key: "quantity_billable"
                    },
                    {
                        name: "Get Date",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    {moment(props.data.ts_start).format('DD-MM-YYYY')}
                                </td>
                            )
                        }
                    },
                    {
                        name: "Completion Date",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    {moment(props.data.ts_stop).format('DD-MM-YYYY')}
                                </td>
                            )
                        }
                    },
                    {
                        name: "Total Minutes",
                        key: "job_calculation_total_minutes"
                    },
                    {
                        name: "Fault",
                        key: "fault_quantity"
                    },
                    {
                        name: "Fault Note",
                        key: "fault_note"
                    }
                ]
                }
                export={true}
            />
        </Container>
    )
}