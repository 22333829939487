import { Button, Container, ListItemButton, ListItemText } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../../containers/containers.reducer";
import checkAccess from "../../../../shared/checkAccess";
import CustomModal from "../../../../shared/CustomModal";
import CustomTable from "../../../../shared/CustomTable";
import notificationError from "../../../../shared/notificationError";
import notificationSuccess from "../../../../shared/notificationSuccess";
import { deleteKoj } from "../koj.api";
import KojEdit from "./KojEdit";

export default function Koj() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [dataSelected, setDataSelected] = useState<any>({})
    const [modalEdit, setModalEdit] = useState(false)
    const [timeGetData, setTimeGetData] = useState<any>(undefined)

    useEffect(() => {
        dispatch(setTitle("Master Data"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([
            {
                name: "Master Data",
                url: ""
            },
            {
                name: "KOJ",
                url: "/master/koj"
            }
        ]))
    }, [])

    const handleCloseModalEdit = (isReload: boolean) => {
        setModalEdit(false)
        if (isReload) {
            setTimeGetData(new Date().getTime())
        }
    }

    const handleDelete = (id: string) => {
        Swal.fire({
            icon: "question",
            title: "Are you sure to delete this data?",
            showCancelButton: true
        })
            .then(response => {
                if (response.isConfirmed) {
                    dispatch<any>(deleteKoj(id))
                        .unwrap()
                        .then((response: any) => {
                            if (response.status === 200) {
                                notificationSuccess("success delete KOJ")
                                setTimeGetData(new Date().getTime())
                            }
                            else {
                                notificationError(response)
                            }
                        })
                }
            })
    }

    return (
        <Container>
            <CustomModal
                open={modalEdit}
                onClose={handleCloseModalEdit}
                title="Edit KOJ"
                component={
                    <KojEdit onClose={handleCloseModalEdit} data={dataSelected} />
                }
            />
            <CustomTable
                url={"/koj"}
                columns={[
                    {
                        name: "Name",
                        key: "name",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    <span style={{ backgroundColor: props.data.color }}>{props.data.name}</span>
                                </td>
                            )
                        }
                    },
                    {
                        name: "Daily Target",
                        key: "target_daily",
                        customRender: (props: any) => {
                            return (
                                <td>{props.data.target_daily || '-'}</td>
                            )
                        }
                    }
                ]}
                headerActions={(props: any) => (
                    <Fragment>
                        {checkAccess("CREATE") &&
                            <Button
                                variant="contained"
                                onClick={() => navigate('/master/koj/add')}
                            >
                                New Koj
                            </Button>
                        }
                    </Fragment>
                )}
                rowActions={(props: any) => (
                    <Fragment>
                        {checkAccess("EDIT") &&
                            <ListItemButton onClick={() => { setDataSelected(props.data); setModalEdit(true) }}>
                                <ListItemText>Edit</ListItemText>
                            </ListItemButton>
                        }
                        {checkAccess("DELETE") &&
                            <ListItemButton onClick={() => handleDelete(props.data.id)}>
                                <ListItemText>Delete</ListItemText>
                            </ListItemButton>
                        }
                    </Fragment>
                )}
                timeGetData={timeGetData}
            />
        </Container>
    )
}