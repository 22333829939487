import { NewReleases, Verified } from "@mui/icons-material"
import { Box, Card, CardContent, CardHeader, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"

export const OvertimeApprovelAnalyze = (props: {
    jobList: any[],
    requestMinute: number
}) => {

    const totalValidOvertimeMinutes = props.jobList.reduce((acc, job) => {
        const minutePerPole = 480 / job.koj_target_daily
        const validOvertimePoles = job.quantity_pole - job.koj_target_daily
        const validOvertimeMinutes = validOvertimePoles * minutePerPole
        return acc + (validOvertimeMinutes < 0 ? 0 : validOvertimeMinutes)
    }, 0)

    return (
        <Card style={{ border: "1px solid grey" }}>
            <CardHeader title="Overtime Approval Analyze" />
            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>KOJ</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Pole QTY</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Daily Target</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Minute Per Pole</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Valid Overtime Poles</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Valid Overtime Minutes</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.jobList.map((job: any, i: number) => {
                            const minutePerPole = 480 / job.koj_target_daily
                            const validOvertimePoles = job.quantity_pole - job.koj_target_daily
                            const validOvertimeMinutes = validOvertimePoles * minutePerPole
                            return (
                                <TableRow>
                                    <TableCell>{job.koj_name}</TableCell>
                                    <TableCell>{job.quantity_pole}</TableCell>
                                    <TableCell>{job.koj_target_daily}</TableCell>
                                    <TableCell>{minutePerPole}</TableCell>
                                    <TableCell>{validOvertimePoles < 0 ? 0 : validOvertimePoles}</TableCell>
                                    <TableCell>{validOvertimeMinutes < 0 ? 0 : validOvertimeMinutes}</TableCell>
                                </TableRow>
                            )
                        })}
                        <TableRow>
                            <TableCell colSpan={5} sx={{ fontWeight: "bold" }}>Total Valid Overtime Minutes</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {totalValidOvertimeMinutes}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={5} sx={{ fontWeight: "bold" }}>Total Request Minutes</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <Typography>
                                        {props.requestMinute}
                                    </Typography>
                                    {props.requestMinute <= totalValidOvertimeMinutes
                                        ? <Verified color="success" />
                                        : <NewReleases color="error" />
                                    }
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
}

export const OvertimeApprovelAnalyzeIcon = (props: {
    jobList: any[],
    requestMinute: number
}) => {

    const totalValidOvertimeMinutes = props.jobList.reduce((acc, job) => {
        const minutePerPole = 480 / job.koj_target_daily
        const validOvertimePoles = job.quantity_pole - job.koj_target_daily
        const validOvertimeMinutes = validOvertimePoles * minutePerPole
        return acc + (validOvertimeMinutes < 0 ? 0 : validOvertimeMinutes)
    }, 0)

    return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography>
                {props.requestMinute}
            </Typography>
            {props.requestMinute <= totalValidOvertimeMinutes
                ? <Verified color="success" />
                : <NewReleases color="error" />
            }
        </Box>
    )
}